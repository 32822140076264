import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { theme } from '../site/styles';
import FooterSection from '@pageSections/common/footer';
import HeaderSection from '@pageSections/homePage/header';
import APISection from '@pageSections/homePage/API';
import { IPrismicHome, PageContext } from '@appTypes';

import CallToActionSection from '@pageSections/common/callToAction';
import Separator from '@components/Separator';
import TrustedBySection from '@pageSections/homePage/trusted-by';
import FAQSection from '@pageSections/homePage/faq';
import TestimonialsSection from '@pageSections/homePage/testimonials';
import DistributeContentSection from '@pageSections/homePage/distribute-content';
import HowItWorksSection from '@pageSections/homePage/how-it-works';
import FeaturedWorkouts from '@pageSections/homePage/featured-workouts';
import BenefitsSection from '@pageSections/homePage/benefits';
import PartnersSection from '@pageSections/homePage/partners';
import { Helmet } from 'react-helmet';
import HeaderVideo from '@pageSections/homePage/header/HeaderVideo';
import { NavigationThemeContext } from '../site/context/NavigationThemeContext';
import BlogSection from '@pageSections/homePage/blog';

const { breakpoints } = theme;

function removeTags(htmlString: string) {
  if (htmlString === null || htmlString === '') return false;
  else htmlString = htmlString.toString();
  return htmlString.replace(/(<([^>]+)>)/gi, '');
}

const HomePage: React.FC<PageProps<IPrismicHome, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData, prismicPartnersData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url, faq_items } = data;

  const faqSchemaOrgJSONLD = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': faq_items.map((item) => {
      return {
        '@type': 'Question',
        'name': item.faq_question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': removeTags(item.faq_answer.html),
        },
      };
    }),
  };

  const { setNavigationTheme } = useContext(NavigationThemeContext);

  useEffect(() => {
    setNavigationTheme!('dark');
    return () => {
      setNavigationTheme!('light');
    };
  }, []);

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>{JSON.stringify(faqSchemaOrgJSONLD)}</script>
      </Helmet>
      <Container>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />
        <HeaderVideo>
          <HeaderSection {...data} />
        </HeaderVideo>
        <TrustedBySection {...data} />
        <BenefitsSection {...data} />
        <FeaturedWorkouts {...data} />
        <HowItWorksSection {...data} />
        <DistributeContentSection {...data} />
        <ApiCustomSeparator label={'OR'} maxWidth={1100} />
        <APISection {...data} />
        <TestimonialsSection {...data} />
        <PartnersSection {...data} prismicPartnersData={prismicPartnersData} />
        <BlogSection {...data} />
        <FAQSection {...data} />
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default HomePage;

const ApiCustomSeparator = styled(Separator)`
  margin: 70px auto;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 100px auto;
  }
`;

const Container = styled.div`
  margin-bottom: 70px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 110px;
  }
`;

export const pageQuery = graphql`
  query HomepageQuery($locale: String!) {
    prismicData: allPrismicHome(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            partners_title
            partners_subtitle
            featured_workouts_title
            featured_workouts_subtitle
            featured_workouts_button_label
            featured_workouts_button_link
            featured_workouts_production
            benefits_title
            benefits_subtitle
            benefits_items {
              benefit_item_image {
                url
                alt
              }
              benefit_item_title
              reverse_title_image
            }
            api_button_id
            api_button_label
            api_image {
              alt
              url
            }
            api_button_link
            api_subtitle
            api_tag_label
            api_title
            blog_button_text
            blog_subtitle
            blog_title
            distribute_image {
              alt
              url
            }
            distribute_steps {
              button_invert_color
              button_link
              button_text
              button_video_modal
              description
              tag_bg_color
              tag_text
              tag_text_color
              title
            }
            distribute_title
            distribute_subtitle
            faq_items {
              faq_answer {
                html
              }
              faq_question
            }
            faq_subtitle
            header_android_link
            faq_title
            header_image {
              alt
              url
            }
            header_ios_link
            header_subtitle {
              html
            }
            header_title {
              html
            }
            header_title_animated_strings
            header_buttons {
              button_label
              button_link
              button_primary
              new_tab
              button_id
            }
            how_it_works_steps {
              intermediate_step
              step_image {
                alt
                url
              }
              step_subtitle
              step_label
              step_title
              video_id
            }
            how_it_works_title {
              html
              text
            }
            how_it_works_subtitle {
              html
              text
            }
            hiw_button_label
            hiw_button_link
            hiw_button_id
            meta_description
            meta_image {
              alt
              url
            }
            meta_keywords
            meta_title
            meta_url
            team_button_id
            team_button_label
            team_button_link
            team_image {
              alt
              url
            }
            team_subtitle
            team_tag_label
            team_title
            testimonials {
              testimonial_image {
                alt
                url
              }
              testimonial_rate_stars
              testimonial_review
              testimonial_reviewer_link
              testimonial_reviewer_name
              testimonial_reviewer_title
            }
            testimonials_subtitle {
              html
              text
            }
            testimonials_title {
              html
              text
            }
            trusted_by_image {
              alt
              url
            }
            trusted_by_image_mobile {
              alt
              url
            }
            trusted_title {
              html
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
    prismicPartnersData: allPrismicPartners(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            partners {
              partner_description {
                html
              }
              partner_email
              partner_locations
              partner_logo {
                url
                alt
              }
              partner_name
              partner_video {
                url
              }
              partner_website
              show_in_homepage
              partner_business_type
            }
          }
        }
      }
    }
  }
`;
