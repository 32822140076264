import React from 'react';
import { SectionSubtitle, SectionTitle, SectionWrapper } from '@styles';
import { HomeDistributeContentSectionData } from './types';
import DistributeSteps from '@components/DistributeSteps';

const DistributeContentSection: React.FC<HomeDistributeContentSectionData> = (props) => {
  const { distribute_title, distribute_subtitle, distribute_image, distribute_steps } = props;

  return (
    <SectionWrapper>
      <SectionTitle>{distribute_title}</SectionTitle>
      <SectionSubtitle>{distribute_subtitle}</SectionSubtitle>
      <DistributeSteps image={distribute_image.url} imageAlt={distribute_image.alt} steps={distribute_steps} />
    </SectionWrapper>
  );
};

export default DistributeContentSection;
