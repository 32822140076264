import React from 'react';
import styled from '@emotion/styled';
import { theme, SectionTitle, SectionSubtitle } from '@styles';
import { HomeAPISectionData } from './types';
import Tag from '@components/Tag';
import { CANDLELIGHT, OUTER_SPACE } from '@constants';
import Button from '@components/Button';
import { useWindowWidth } from '@customHooks';
import { Link } from 'gatsby';
import { isBrowser } from '@utils';

const { breakpoints } = theme;

const API: React.FC<HomeAPISectionData> = (props) => {
  const { api_title, api_tag_label, api_subtitle, api_button_label, api_button_link, api_button_id, api_image } = props;
  const windowWidth = useWindowWidth();

  const breakpointSmallLaptop = 769;
  const breakpointIsMobile = windowWidth < breakpointSmallLaptop;

  return (
    <Wrapper>
      <SectionTitleContainer>
        <SectionTitle>{api_title}</SectionTitle>
      </SectionTitleContainer>
      <SectionSubtitle>{api_subtitle}</SectionSubtitle>
      <Padding>
        <ApiImageContainer>
          <TagContainer>
            <Tag label={api_tag_label} color={OUTER_SPACE} backgroundColor={CANDLELIGHT} width={121} height={30} />
          </TagContainer>
          <img src={api_image.url} alt={api_image.alt} />
        </ApiImageContainer>
      </Padding>
      {isBrowser() && (
        <ButtonContainer>
          <Link to={`${api_button_link}${window.location.search}`}>
            <Button
              id={api_button_id}
              fontSize={breakpointIsMobile ? '14px' : '16px'}
              height={breakpointIsMobile ? 52 : 60}
              label={api_button_label}
              uppercase={false}
            ></Button>
          </Link>
        </ButtonContainer>
      )}
    </Wrapper>
  );
};

export default API;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  margin: 32px 24px 0px 24px;
  a {
    text-decoration: none;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 0px;
  }
`;

const Padding = styled.div`
  padding: 0 24px;
`;

const TagContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -15px;
`;

const ApiImageContainer = styled.div`
  position: relative;
  max-width: 755px;
  margin: 0 auto;
  margin-bottom: 40px;
  img {
    border-radius: 14px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 72px;
    margin-bottom: 24px;
    img {
      border-radius: 30px;
    }
  }
`;

const SectionTitleContainer = styled.div`
  margin: 0 auto;
  h3 {
    @media screen and (min-width: ${breakpoints.smallLaptop}) {
      line-height: 50px;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 34px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
  }
`;
