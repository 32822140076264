import React from 'react';
import styled from '@emotion/styled';
import { theme, SectionTitle, SectionSubtitle } from '@styles';
import { Testimonial } from '@appTypes';
import TestimonialCarousel from '@components/Carousels/TestimonialCarousel';
import { HomeTestimonialsSectionData } from './types';

const { breakpoints } = theme;

const TestimonialsSection: React.FC<HomeTestimonialsSectionData> = (props) => {
  const { testimonials_title, testimonials_subtitle, testimonials } = props;
  const items: Testimonial[] = testimonials.map((testimonial) => {
    return {
      rating: testimonial.testimonial_rate_stars,
      message: testimonial.testimonial_review,
      customer: testimonial.testimonial_reviewer_name,
      customerDescription: testimonial.testimonial_reviewer_title,
      customerLink: testimonial.testimonial_reviewer_link,
      image: testimonial.testimonial_image.url || 'https://via.placeholder.com/800',
    };
  });
  return (
    <Wrapper>
      <SectionTitle>{testimonials_title.text}</SectionTitle>
      <SectionSubtitle>{testimonials_subtitle.text}</SectionSubtitle>
      <TestimonialCarousel testimonials={items} />
    </Wrapper>
  );
};

export default TestimonialsSection;

const Wrapper = styled.div`
  background: #f0f5f5;
  margin: 60px auto;
  padding: 60px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    background: linear-gradient(180deg, #f0f5f5 0%, rgba(240, 245, 245, 0) 100%);
    border-radius: 40px;
    margin: 240px auto;
    padding: 120px 0px;
  }
`;
