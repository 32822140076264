import React from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import TestimonialCard from '@components/Cards/TestimonialCard';
import { Testimonial } from '@appTypes';

type Props = {
  testimonials: Testimonial[];
};

const TestimonialCarousel: React.FC<Props> = ({ testimonials }) => {
  return (
    <Swiper
      className='testimonial-carousel'
      modules={[Autoplay, Pagination]}
      centeredSlides={true}
      autoHeight={true}
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
    >
      {testimonials.map((testimonial, index) => {
        return (
          <SwiperSlide key={index}>
            <TestimonialCard testimonial={testimonial} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default TestimonialCarousel;
