import React from 'react';
import styled from '@emotion/styled';
import { SectionTitle, SectionSubtitle, theme } from '@styles';
import FAQ, { FAQProps } from '@components/FAQ';
import { HomeFAQSectionData } from './types';

const { breakpoints } = theme;

const FAQSection: React.FC<HomeFAQSectionData> = (props) => {
  const { faq_title, faq_subtitle, faq_items } = props;
  const items: FAQProps[] = faq_items.map((item) => {
    return {
      question: item.faq_question,
      answer: item.faq_answer.html,
    };
  });

  return (
    <Wrapper>
      <SectionTitle>{faq_title}</SectionTitle>
      <SectionSubtitle>{faq_subtitle}</SectionSubtitle>
      <FAQWrapper>
        <FAQ items={items} />
      </FAQWrapper>
    </Wrapper>
  );
};

export default FAQSection;

const Wrapper = styled.div`
  margin: 70px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 120px 0px;
    margin-bottom: -50px;
  }
`;

const FAQWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;
