import React from 'react';
import styled from '@emotion/styled';
import { MOUNTAIN_MEADOW, MYSTIC, OUTER_SPACE } from '@constants';
import { theme } from '@styles';
import Tag from '@components/Tag';

const { breakpoints } = theme;

type Step = {
  step_title?: string;
  step_subtitle: string;
  step_label?: string;
  intermediate_step?: boolean;
};

type Props = {
  stepsArray: Step[];
  onStepChange: Function;
  currentStep: number;
};

const AppCarouselSteps: React.FC<Props> = ({ stepsArray, onStepChange, currentStep }) => {
  return (
    <Container>
      <StepsWrapper>
        {stepsArray &&
          stepsArray.map(({ step_label, step_subtitle, step_title }, index) => (
            <StepWrapper
              key={index}
              active={currentStep === index}
              onClick={() => {
                onStepChange(index);
              }}
            >
              {step_label ? (
                <Tag label={step_label} backgroundColor={currentStep === index ? MOUNTAIN_MEADOW : OUTER_SPACE}></Tag>
              ) : (
                <TagRectangle isActive={currentStep === index} />
              )}
              <StepText isHidden={currentStep !== index}>
                <StepTitle>{step_title}</StepTitle>
                <StepSubtitle>{step_subtitle}</StepSubtitle>
              </StepText>
            </StepWrapper>
          ))}
      </StepsWrapper>
    </Container>
  );
};

export default AppCarouselSteps;

const TagRectangle = styled.div<{ isActive: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background: ${({ isActive }) => (isActive ? MOUNTAIN_MEADOW : MYSTIC)};
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: none;
  }
`;

const StepText = styled.div<{ isHidden: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  position: absolute;
  left: 0;
  text-align: center;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: block;
    position: relative;
    text-align: initial;
    margin: initial;
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  background: ${MYSTIC};
  height: 4px;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  margin: 0px 24px 100px 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    align-items: initial;
    height: initial;
    height: 4px;
    position: relative;
    margin: 0px 24px 170px 24px;
    &:before: {
      content: '';
      top: -40px;
      position: absolute;
      width: 100%;
      height: 4px;
      background: #e2ebeb;
    }
  }
`;

const StepWrapper = styled.div<{ active: boolean }>`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 40px;
    position: relative;
    &:before {
      content: '';
      top: -40px;
      position: absolute;
      width: 100%;
      height: 4px;
      background: ${({ active }) => (active ? MOUNTAIN_MEADOW : 'transparent')};
    }
  }
`;

const StepTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  margin-top: 10px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 16px;
    line-height: 24px;
    max-width: 243px;
    margin-bottom: 8px;
  }
`;

const StepSubtitle = styled.div`
  font-size: 16px;
  color: ${OUTER_SPACE};
  line-height: 20.8px;
  font-weight: 400;
  max-width: 243px;
`;

const Container = styled.div`
  display: flex;
`;
