import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import VideoWiz1 from '@assets/videos/video-wiz-1.mp4';
import VideoWiz2 from '@assets/videos/video-wiz-2.mp4';
import VideoWiz3 from '@assets/videos/video-wiz-3.mp4';
import VideoWiz4 from '@assets/videos/video-wiz-4.mp4';
import { theme } from '@styles';

const { breakpoints } = theme;

type Props = {};

const HeaderVideo: React.FC<Props> = ({ children }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videos = [VideoWiz1, VideoWiz2, VideoWiz3, VideoWiz4];
  const [playingVideo, setPlayingVideo] = useState<number>(0);

  const handleVideoEnded = () => {
    if (playingVideo === videos.length - 1) {
      setPlayingVideo(0);
    } else {
      setPlayingVideo(playingVideo + 1);
    }
  };

  return (
    <>
      <Wrapper>{children}</Wrapper>
      <Video
        ref={videoRef}
        src={videos[playingVideo]}
        autoPlay
        muted
        playsInline
        onTimeUpdate={() => {
          const videoDuration = videoRef.current?.duration;
          const currentTime = videoRef.current?.currentTime;
          if (currentTime === videoDuration) {
            handleVideoEnded();
          }
        }}
      />
      <VideoOverlay />
    </>
  );
};

export default HeaderVideo;

const Wrapper = styled.div`
  background: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  bottom: calc(70px + 20px);
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    bottom: calc(70px + 20px);
  }
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 48, 36, 0.7);
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;
