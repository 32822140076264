import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { SectionSubtitle, SectionTitleH1, SectionWrapper, theme } from '@styles';
import axios from 'axios';
import consola from 'consola';
import { Link } from 'gatsby';
import { HomeFeaturedWorkoutsSectionData } from './types';
import Button from '@components/Button';
import WorkoutCard from '@components/Cards/v1/WorkoutCard';
import { IWorkout } from '@appTypes';
import { isBrowser } from '@utils';

const { breakpoints } = theme;

const FeaturedWorkouts: React.FC<HomeFeaturedWorkoutsSectionData> = (props) => {
  const {
    featured_workouts_title,
    featured_workouts_subtitle,
    featured_workouts_button_label,
    featured_workouts_button_link,
    featured_workouts_production,
  } = props;
  const [playingVideoIndex, setPlayingVideoIndex] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [featuredWorkoutsItems, setFeaturedWorkoutsItems] = useState<IWorkout[]>([]);

  useEffect(() => {
    getFeaturedWorkouts();
  }, []);

  const getFeaturedWorkouts = async () => {
    const featuredWorkoutsIds = featured_workouts_production.split(',');
    for await (const featuredWorkout of featuredWorkoutsIds) {
      const workoutId = featuredWorkout.replace(/\s/g, '');
      try {
        const { data }: { data: any } = await axios.get(`${process.env.GATSBY_CONTENT_API_URL}/workouts/${workoutId}`, {
          headers: {
            'X-Api-Key': process.env.GATSBY_CONTENT_API_KEY,
          },
        });
        if (Object.keys(data)) {
          setFeaturedWorkoutsItems((oldArr) => [...oldArr, data.data]);
        }
      } catch (error) {
        consola.error('[getFeaturedWorkouts] ', error);
      }
    }
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    if (isBrowser()) {
      setWindowWidth(window.innerWidth);
    }
  };

  const showOneWorkout = windowWidth < 770;

  const handleVideoPlay = (event: Event) => {
    const target = event.target as HTMLButtonElement;
    const cardElement = target.parentElement;
    const index = cardElement && cardElement.getAttribute('data-index');
    if (playingVideoIndex === index) {
      setPlayingVideoIndex(null);
    } else {
      setPlayingVideoIndex(Number(index));
    }
  };

  return (
    <SectionWrapper>
      <SectionTitleH1>{featured_workouts_title}</SectionTitleH1>
      <SectionSubtitle>{featured_workouts_subtitle}</SectionSubtitle>
      <Body>
        <WorkoutsList>
          {featuredWorkoutsItems &&
            featuredWorkoutsItems.slice(0, showOneWorkout ? 1 : 3).map((workout, index) => {
              return (
                <WorkoutCard
                  key={index}
                  shouldPause={playingVideoIndex !== index}
                  index={index}
                  workout={workout}
                  onVideoPlay={handleVideoPlay}
                ></WorkoutCard>
              );
            })}
        </WorkoutsList>
        <LinkWrapper to={featured_workouts_button_link}>
          <Button label={featured_workouts_button_label} fontSize={14} width={209} height={50} />
        </LinkWrapper>
      </Body>
    </SectionWrapper>
  );
};

export default FeaturedWorkouts;

const LinkWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: 94px;
`;

const WorkoutsList = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1110px;
    display: flex;
    margin: 0 auto;
  }
`;

const Body = styled.div`
  margin: 0 24px;
  a {
    text-decoration: none;
  }
`;
