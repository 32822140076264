import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { theme } from '@styles';
import AppCarouselSteps from './AppCarouselSteps';

const { breakpoints } = theme;

type Step = {
  step_title?: string;
  step_subtitle: string;
  step_label?: string;
  intermediate_step?: boolean;
};

type Props = {
  steps: Step[];
  placeholdersMapper: string[];
  appName?: string;
};

const AppCarousel: React.FC<Props> = ({ steps, placeholdersMapper, appName }) => {
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const carouselLoopRef = useRef<ReturnType<typeof setTimeout>>(setTimeout(() => '', 0));

  useEffect(() => {
    carouselLoopRef.current = setTimeout(() => {
      if (currentStep < placeholdersMapper.length - 1) {
        setCurrentStep(currentStep + 1);
        setCurrentVideoId(currentStep + 1);
      } else {
        setCurrentStep(0);
        setCurrentVideoId(0);
      }
    }, 3000);
  }, [currentStep]);

  const handleOnStepChange = (step: number) => {
    setCurrentStep(step);
    setCurrentVideoId(step);
    clearTimeout(carouselLoopRef.current);
  };

  return (
    <Container>
      <StepImage>
        {placeholdersMapper.map(
          (_, index) =>
            steps[index] && (
              <PlaceholderImage
                alt={steps[index].step_title ? steps[index].step_title : steps[index].step_subtitle}
                key={index}
                showImage={currentVideoId === index}
                src={placeholdersMapper[index]}
              />
            ),
        )}
      </StepImage>
      <AppCarouselSteps
        currentStep={currentStep}
        onStepChange={(step: number) => handleOnStepChange(step)}
        stepsArray={steps}
      />
    </Container>
  );
};

export default AppCarousel;

const StepImage = styled.div`
  position: relative;
  align-self: center;
  user-select: none;
  margin: 0px 27px 60px 27px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 0px auto 60px auto;
    height: 644px;
  }
`;

const PlaceholderImage = styled.img<{ showImage: boolean }>`
  transition: opacity 250ms ease-in;
  display: ${(props) => (props.showImage ? 'inline-flex' : 'none')};
  z-index: 1;
  border-radius: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1100px;
`;
