import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { theme, SectionWrapper as SectionWrapperStyles } from '@styles';
import { HomeHeaderSectionData } from './types';
import { GRANNY_SMITH, MOUNTAIN_MEADOW, OUTER_SPACE, WHITE } from '@constants';
import { Link } from 'gatsby';
import Button from '@components/Button';
import { commaSeparatedStringsToArray } from '@utils';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';

const { breakpoints } = theme;

const HeaderSection: React.FC<HomeHeaderSectionData> = (props) => {
  const { header_title, header_subtitle, header_buttons, header_title_animated_strings } = props;

  const theme = 'dark';

  const cubicIndex = useMotionValue(0);
  const cubicValues = commaSeparatedStringsToArray(header_title_animated_strings);
  const cubicLoopRef = useRef<ReturnType<typeof setTimeout>>(setTimeout(() => '', 0));

  useEffect(() => {
    cubicLoopRef.current = setInterval(() => {
      if (cubicIndex.get() < cubicValues.length - 1) {
        animate(cubicIndex, cubicIndex.get() + 1, {
          duration: 0.25,
        });
      } else {
        animate(cubicIndex, 0, {
          duration: 0,
        });
      }
    }, 3000);
    return () => {
      clearInterval(cubicLoopRef.current);
    };
  }, []);

  return (
    <SectionWrapper id='featuresHeader'>
      <TitleWrapper>
        <Title
          theme={theme}
          dangerouslySetInnerHTML={{
            __html: header_title.html,
          }}
        />
        <CubicValuesWrapper>
          {cubicValues.map((item, index) => {
            const translateY = useTransform(cubicIndex, [index - 1, index, index + 1], [10, 0, -10]);
            const opacity = useTransform(cubicIndex, [index - 1, index, index + 1], [0, 1, 0]);

            return (
              <CubicValue
                key={index}
                style={{
                  opacity: opacity,
                  translateY: translateY,
                }}
              >
                <CubicText>{item}</CubicText>
              </CubicValue>
            );
          })}
        </CubicValuesWrapper>
        <HeaderSubtitleWrapper>
          <Subtitle
            theme={theme}
            dangerouslySetInnerHTML={{
              __html: header_subtitle.html,
            }}
          />
        </HeaderSubtitleWrapper>
        <HeaderButtons>
          {header_buttons.map(({ button_id, button_label, button_link, button_primary, new_tab }, index) => {
            return (
              <Link key={index} to={button_link} id={button_id} target={new_tab ? '_blank' : '_self'}>
                <Button
                  theme={'dark'}
                  id={button_id}
                  label={button_label}
                  type={button_primary ? 'primary' : 'secondary'}
                  key={index}
                  height={53}
                  width={157}
                />
              </Link>
            );
          })}
        </HeaderButtons>
      </TitleWrapper>
    </SectionWrapper>
  );
};

export default HeaderSection;

const CubicText = styled(motion.div)`
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  font-family: 'Poppins';
  background: -webkit-linear-gradient(#10fda7, #21c98c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 62px;
    line-height: 74.4px;
  }
`;

const CubicValuesWrapper = styled(motion.div)`
  margin-top: -28px;
  margin-bottom: 16px;
  position: relative;
  height: 60px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    height: 70px;
  }
`;

const CubicValue = styled(motion.div)`
  display: flex;
  position: absolute;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 17px;
  justify-content: flex-start;
  margin-top: 24px;
  margin-left: -8px;
  a {
    text-decoration: none;
  }
  svg {
    height: 40px;
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 34px;
    justify-content: flex-start;
    svg {
      height: 50px;
      width: 100%;
    }
  }
`;

const Subtitle = styled.div`
  h2 {
    font-weight: 400;
    color: ${({ theme }) => (theme === 'dark' ? WHITE : GRANNY_SMITH)};
    font-size: 24px;
    line-height: 33.6px;
    opacity: 0.8;
  }
`;

const Title = styled.div`
  h1 {
    color: ${({ theme }) => (theme === 'dark' ? WHITE : OUTER_SPACE)};
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -2px;
    font-family: Poppins;
  }
  em {
    user-select: none;
    font-style: normal;
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 8px;
      width: 102%;
      left: 50%;
      transform: translateX(-50%) rotate(-2deg);
      height: 5px;
      background: ${MOUNTAIN_MEADOW};
      z-index: -2;
    }
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    em {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 13px;
        width: 102%;
        left: 50%;
        transform: translateX(-50%) rotate(-1deg);
        height: 10px;
        background: ${MOUNTAIN_MEADOW};
        z-index: -2;
      }
    }
    h1 {
      font-size: 62px;
      line-height: 74.4px;
    }
  }
`;

const TitleWrapper = styled.div`
  margin: 0 24px;
  h1 {
    max-width: 290px;
  }
  h2 {
    max-width: 300px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 50px auto 0px auto;
    h1 {
      max-width: 660px;
    }
    h2 {
      max-width: 660px;
    }
  }
`;

const SectionWrapper = styled(SectionWrapperStyles)`
  max-width: 1200px;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin-top: 80px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
  }
`;

const HeaderSubtitleWrapper = styled.div`
  margin: 0 auto;
`;
