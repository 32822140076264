import React from 'react';
import styled from '@emotion/styled';
import { SectionSubtitle, SectionTitle, theme } from '@styles';
import { HomeHowItWorksSectionData } from './types';
import Button from '@components/Button';
import { Link } from 'gatsby';
import AppCarousel from '@components/Carousels/AppCarousel';

const { breakpoints } = theme;

const HowItWorksSection: React.FC<HomeHowItWorksSectionData> = (props) => {
  const {
    how_it_works_title,
    how_it_works_subtitle,
    how_it_works_steps,
    hiw_button_id,
    hiw_button_label,
    hiw_button_link,
  } = props;
  const placeholdersMapper = how_it_works_steps.map((step) => step.step_image.url);

  return (
    <Wrapper>
      <SectionTitle>{how_it_works_title.text}</SectionTitle>
      <SectionSubtitle>{how_it_works_subtitle.text}</SectionSubtitle>
      <AppCarousel steps={how_it_works_steps} placeholdersMapper={placeholdersMapper} />
      <ButtonWrapper>
        <ButtonLink id={hiw_button_id} to={hiw_button_link}>
          <Button fontSize={16} id={hiw_button_id} label={hiw_button_label} height={53} />
        </ButtonLink>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default HowItWorksSection;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  display: inline-flex;
`;

const Wrapper = styled.div`
  background: linear-gradient(180deg, #f0f5f5 0%, rgba(240, 245, 245, 0) 100%);
  padding: 60px 0px 100px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 120px auto;
    padding: 120px 0px;
  }
`;
