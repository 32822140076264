import React, { useState } from 'react';
import styled from '@emotion/styled';
import { OUTER_SPACE, SUBMARINE } from '@constants';
import Tag from './Tag';
import { theme } from '@styles';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import CloseIcon from '@assets/icons/CloseIcon';
import { isBrowser } from '@utils';

const { breakpoints } = theme;

Modal.defaultStyles.overlay = {
  ...Modal.defaultStyles.overlay,
  display: 'flex',
  alignItems: 'center',
  zIndex: 999,
  position: 'fixed',
  backgroundColor: 'rgba(52,69,78,0.5)',
};

export type DistributeStepType = {
  tag_text: string;
  tag_text_color: string;
  tag_bg_color: string;
  title: string;
  description: string;
  button_text: string;
  button_link: string;
  button_video_modal: boolean;
  button_invert_color: boolean;
};

type Props = {
  image: string;
  imageAlt: string;
  steps: DistributeStepType[];
};

const DistributeSteps: React.FC<Props> = ({ image, imageAlt, steps }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const toggleVideoModal = (index: number) => {
    setModalIsOpen(!modalIsOpen);
    setStepIndex(index);
  };

  const opts = {
    height: '100%',
    width: '100%',
  };

  return (
    <Wrapper>
      <img src={image} alt={imageAlt} />
      <Steps>
        {steps.map((step, index) => {
          const {
            tag_text,
            tag_text_color,
            tag_bg_color,
            title,
            description,
            button_text,
            button_link,
            button_video_modal,
            button_invert_color,
          } = step;
          return (
            <DistributeStep key={index}>
              <Tag width={'auto'} height={30} label={tag_text} backgroundColor={tag_bg_color} color={tag_text_color} />
              <DistributeStepTitle>{title}</DistributeStepTitle>
              <DistributeSubtitle>{description}</DistributeSubtitle>
              {button_link && isBrowser() && (
                <DistributeButton
                  invertColor={button_invert_color}
                  href={button_video_modal ? null : `${button_link}${window.location.search}`}
                  onClick={button_video_modal ? () => toggleVideoModal(index) : () => {}}
                >
                  <div>{button_text}</div>
                </DistributeButton>
              )}
            </DistributeStep>
          );
        })}
      </Steps>
      <Modal
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        isOpen={modalIsOpen}
        contentLabel='Example Modal'
        style={modalContentStyles}
        ariaHideApp={false}
        onRequestClose={() => {
          toggleVideoModal(0);
        }}
      >
        <ModalInnerContainer>
          <ModalClose>
            <CloseIcon onClick={() => toggleVideoModal(0)} color={'white'} width={48} height={48} />
          </ModalClose>
          <YouTube containerClassName='youtubeVideoPlayer' videoId={steps[stepIndex].button_link} opts={opts} />
        </ModalInnerContainer>
      </Modal>
    </Wrapper>
  );
};

export default DistributeSteps;

const modalContentStyles = {
  content: {
    inset: '0px',
    zIndex: 999,
    position: 'relative',
    width: '80vw',
    height: '472px',
    maxWidth: '896px',
    margin: '0 auto',
    padding: '0px',
    border: 'none',
    borderRadius: '14px',
    marginTop: '20px',
    overflow: 'initial',
  },
};

const ModalClose = styled.div`
  position: absolute;
  top: -56px;
  z-index: 999;
  right: 0;
  cursor: pointer;
`;

const ModalInnerContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  & .youtubeVideoPlayer {
    display: flex;
    flex: 1;
    & iframe {
      margin: 0;
      border-radius: 14px;
    }
  }
`;

const Wrapper = styled.div`
  margin: 0 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    img {
      max-height: initial;
    }
    max-width: 1100px;
    margin: 0 auto;
  }
`;

const DistributeButton = styled.a<{ invertColor: boolean }>`
  cursor: pointer;
  height: 50px;
  border-radius: 11px;
  color: ${({ invertColor }) => (invertColor ? '#fff' : OUTER_SPACE)};
  background: ${({ invertColor }) => (invertColor ? OUTER_SPACE : 'initial')};
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 700;
  text-decoration: none;
  border: ${({ invertColor }) => (invertColor ? 'none' : `1px solid ${SUBMARINE}`)};
  margin-top: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  transform: translateY(0px);
  &:hover {
    cursor: pointer;
    transform: translateY(-3px);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  }
  transition: all 250ms ease-in-out;
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
  }
`;

const DistributeStep = styled.div`
  margin-bottom: 32px;
  &:nth-of-type(3) {
    margin-bottom: 0px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: initial;
    &:nth-of-type(1) {
      width: 288px;
    }
    &:nth-of-type(2) {
      width: 272px;
    }
    &:nth-of-type(3) {
      width: 266px;
    }
  }
`;

const DistributeStepTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.1px;
  margin-top: 10px;
  font-family: 'Poppins';
`;

const DistributeSubtitle = styled.div`
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 400;
`;
