import React from 'react';
import styled from '@emotion/styled';
import { Testimonial } from '@appTypes';
import { GRANNY_SMITH, MYSTIC, OUTER_SPACE } from '@constants';
import { theme } from '@styles';

const { breakpoints } = theme;

type Props = {
  testimonial: Testimonial;
};

const TestimonialCard: React.FC<Props> = ({ testimonial }) => {
  const { message, customer, customerDescription, image, customerLink } = testimonial;
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={image} alt='Testimonial image' />
      </ImageWrapper>
      <Body>
        <Message>{message}</Message>
        <CustomerLink href={customerLink} target='_blank' rel='noreferrer'>
          <Customer>{customer}</Customer>
          <CustomerDescription>{customerDescription}</CustomerDescription>
        </CustomerLink>
      </Body>
    </Wrapper>
  );
};

export default TestimonialCard;

const CustomerLink = styled.a<{ href: string | undefined }>`
  text-decoration: none;
  &:hover {
    opacity: ${({ href }) => (href ? 0.75 : 1)};
  }
`;

const CustomerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  color: ${GRANNY_SMITH};
  max-width: 200px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: initial;
  }
`;

const Customer = styled.div`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  font-weight: 700;
  font-family: 'Poppins';
  margin-top: 24px;
  color: ${OUTER_SPACE};
`;

const Message = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 23.4px;
  letter-spacing: -0.1px;
  font-style: italic;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 24px;
    line-height: 31.2px;
    flex: 1;
  }
`;

const Body = styled.div`
  padding: 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 32px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 230px;
    border-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    object-fit: cover;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: flex;
    width: calc(50% + 64px + 9px);
    height: 350px;
    img {
      height: 100%;
      border-radius: 30px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      object-fit: cover;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${MYSTIC};
  margin: 0 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    border-radius: 30px;
    width: 1000px;
    height: 350px;
    margin: 0 auto;
  }
`;
