import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { SectionTitle, SectionSubtitle, theme } from '@styles';
import { Item } from '@components/ItemsGrid';
import ItemsGrid from '@components/ItemsGrid';
import { HomeBlogSectionData } from './types';
import { request } from '@utils';
import Button from '@components/Button';
import { Link } from 'gatsby';
import { isBrowser } from '@utils';

const { breakpoints } = theme;

type BlogPost = {
  link: string;
  content: {
    title: string;
    excerpt: string;
  };
  jetpack_featured_media_url: string;
};

const BlogSection: React.FC<HomeBlogSectionData> = (props) => {
  const { blog_title, blog_subtitle, blog_button_text } = props;
  const [posts, setPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    getLatestPosts();
  }, []);

  const items: Item[] = posts.map((blogPost) => {
    return {
      title: blogPost.content.title,
      description: blogPost.content.excerpt,
      thumbnail: blogPost.jetpack_featured_media_url,
      link: blogPost.link,
    };
  });

  const getLatestPosts = async () => {
    const response = await request('https://blog.hyperhuman.cc/wp-json/wp/v2/posts?per_page=4');
    if (response?.data) {
      const posts = response.data;
      setPosts(posts.slice(0, 4));
    }
  };

  return (
    <Wrapper>
      <SectionTitle>{blog_title}</SectionTitle>
      <SectionSubtitle>{blog_subtitle}</SectionSubtitle>
      <ItemsWrapper>
        <ItemsGrid items={items} />
        {isBrowser() && (
          <ButtonWrapper to={`https://blog.hyperhuman.cc${window.location.search}`} target='_blank'>
            <ButtonStyles label={blog_button_text} uppercase={false} fontSize={14} />
          </ButtonWrapper>
        )}
      </ItemsWrapper>
    </Wrapper>
  );
};

export default BlogSection;

const ButtonWrapper = styled(Link)`
  text-decoration: none;
`;

const ButtonStyles = styled(Button)`
  width: 212px;
  margin: 78px auto 0px auto;
`;

const Wrapper = styled.div`
  background: #f0f5f5;
  max-width: 1400px;
  padding: 60px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    padding: 120px auto;
    margin: 240px auto;
    border-radius: 40px;
  }
`;

const ItemsWrapper = styled.div`
  max-width: 930px;
  margin: 0 auto;
`;
