import React from 'react';
import styled from '@emotion/styled';
import { theme } from '@styles';
import { HomePartnersSectionData } from './types';
import Button from '@components/Button';
import { Link } from 'gatsby';
import { GRANNY_SMITH, MYSTIC } from '@constants';
import { limitStringCharacters } from '@utils';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/react-splide/css';
import { useWindowWidth } from '@customHooks';

const { breakpoints } = theme;

const PartnersSection: React.FC<HomePartnersSectionData> = (props) => {
  const { partners_title, partners_subtitle, prismicPartnersData } = props;
  const { partners } = prismicPartnersData!.edges[0].node.data;
  const windowWidth = useWindowWidth();

  const breakpointSmallLaptop = 769;
  const breakpointIsMobile = windowWidth < breakpointSmallLaptop;

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{partners_title}</Title>
        <Subtitle>{partners_subtitle}</Subtitle>
        <ButtonWrapper>
          <Link to='/partners'>
            <Button
              label={'CHECK OUR PARTNERS'}
              fontSize={breakpointIsMobile ? '14px' : '16px'}
              width={225}
              height={53}
            />
          </Link>
        </ButtonWrapper>
      </TitleWrapper>
      <SplideWrapper>
        <Splide
          extensions={{
            Grid,
            AutoScroll,
          }}
          options={{
            direction: 'ttb',
            rewind: true,
            arrows: false,
            pagination: false,
            height: 670,
            type: 'loop',
            drag: false,
            grid: {
              rows: 2,
              cols: 2,
              gap: {
                row: '0px',
                col: '24px',
              },
            },
            autoScroll: {
              speed: 0.8,
              pauseOnHover: false,
            },
            breakpoints: {
              769: {
                direction: 'rtl',
                grid: false,
                perPage: 1,
                width: 375,
                height: 337,
              },
            },
          }}
        >
          {partners.map((partner, index) => {
            return (
              <SplideSlide key={index}>
                <Card>
                  <CardHeader>
                    <img src={partner.partner_logo.url} />
                  </CardHeader>
                  <CardBody>
                    <CardTitle>{partner.partner_name}</CardTitle>
                    <CardSubtitle>
                      <div>{limitStringCharacters(`${partner.partner_business_type}`, 56)}</div>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </SplideSlide>
            );
          })}
        </Splide>
      </SplideWrapper>
    </Wrapper>
  );
};

export default PartnersSection;

const SplideWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    position: relative;
    &:before,
    &:after {
      content: '';
      height: 193px;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 2;
    }
    &:before {
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      top: 0px;
    }
    &:after {
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
      bottom: 0px;
    }
  }
`;

const CardHeader = styled.div`
  height: 187px;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 30px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  font-family: 'Poppins';
  line-height: 27px;
  letter-spacing: -0.1px;
  text-align: left;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    text-align: right;
  }
`;

const CardSubtitle = styled.div`
  margin-top: 4px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  color: ${GRANNY_SMITH};
  text-align: left;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    text-align: right;
  }
`;

const CardBody = styled.div`
  padding: 24px;
  border-top: 1px solid ${MYSTIC};
`;

const Card = styled.div`
  width: 90%;
  border-radius: 30px;
  border: 1px solid ${MYSTIC};
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: -0.3px;
  font-family: 'Poppins';
  margin-bottom: 8px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-weight: 700;
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 20.8px;
  color: ${GRANNY_SMITH};
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;
  margin: 0 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 0px;
  }
`;

const Wrapper = styled.div`
  margin: 60px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 78px;
  flex-direction: column;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    border-radius: 40px;
    margin: 240px auto;
    max-width: 1400px;
  }
`;
