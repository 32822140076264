import React from 'react';
import styled from '@emotion/styled';
import { SectionSubtitle, SectionTitleH1, theme } from '@styles';
import { HomeBenefitsSectionData } from './types';

const { breakpoints } = theme;

const BenefitsSection: React.FC<HomeBenefitsSectionData> = (props) => {
  const { benefits_items, benefits_subtitle, benefits_title } = props;

  return (
    <Wrapper>
      <SectionTitleH1>{benefits_title}</SectionTitleH1>
      <SectionSubtitle>{benefits_subtitle}</SectionSubtitle>
      <Body>
        <Benefits>
          {benefits_items.map((item, index) => {
            const titleMarginTop = item.reverse_title_image && index === 2 ? '-50px' : '0px';

            const contentMarginBottom = index === 0 ? '100px' : index === 3 ? '-90px' : '0px';

            const imageLeftPosition = index === 2 ? -10 : 0;

            const imageWidth = index === 0 ? '300px' : index === 2 ? '331px' : index === 3 ? '240px' : 'auto';

            return (
              <Benefit marginBottom={contentMarginBottom}>
                <BenefitContent reversed={item.reverse_title_image}>
                  <BenefitTitle marginTop={titleMarginTop}>{item.benefit_item_title}</BenefitTitle>
                  <BenefitImage
                    left={imageLeftPosition}
                    width={imageWidth}
                    reversed={item.reverse_title_image}
                    src={item.benefit_item_image.url}
                  />
                </BenefitContent>
              </Benefit>
            );
          })}
        </Benefits>
      </Body>
    </Wrapper>
  );
};

export default BenefitsSection;

const Wrapper = styled.div`
  margin: 60px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 200px 0px 280px 0px;
  }
`;

const BenefitContent = styled.div<{ reversed?: boolean }>`
  display: flex;
  flex-direction: ${({ reversed }) => (reversed ? 'column-reverse' : 'column')};
  justify-content: space-between;
  position: relative;
  height: 100%;
`;

const BenefitImage = styled.img<{ reversed?: boolean; marginBottom?: string; left?: number }>`
  margin: 21px auto;
`;

const BenefitTitle = styled.div<{ marginTop?: string }>`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  font-family: Poppins;
  padding: 32px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: ${({ marginTop }) => marginTop};
  }
`;

const Benefits = styled.div`
  max-width: 862px;
  display: flex;
  margin: 0 auto;
  gap: 62px;
  flex-wrap: wrap;
  align-items: flex-end;
  flex-direction: column;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
  }
`;

const Benefit = styled.div<{ reversed?: boolean; marginBottom?: string }>`
  background: #f0f5f5;
  border-radius: 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    height: 420px;
    width: calc(50% - 62px);
    margin-bottom: ${({ marginBottom }) => marginBottom};
  }
`;

const Body = styled.div`
  margin: 40px 24px 0px 24px;
  a {
    text-decoration: none;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 140px 24px 0px 24px;
  }
`;
